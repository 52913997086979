@import '../src/styles/colorMapping.scss';
.logged-in-layout {
  min-height: 100vh;
}

h1 {
  font-size: 1.5em;
  padding: 8px;
  font-weight: normal;
}
h2 {
  font-size: 1.5em;
  // padding: 8px;
  font-weight: normal;
}

body {
  font-family: 'Montserrat', sans-serif;
  min-width: 1024px;
  // font-weight: 500;
}

::-webkit-scrollbar {
  width: 0.9rem;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #666666;
  border: 5px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

.ant-card .ant-card-body {
  padding: 16px;
  border-radius: 0 0 8px 8px;
}

.custom-modal .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}
.custom-modal .ant-modal-footer button {
  margin-left: 10px;
}

h1 {
  font-weight: 500; /* or you can use a specific value like 700 */
}

h2 {
  font-weight: 500; /* or you can use a specific value like 700 */
}

.modal-buttons {
  justify-content: flex-end;
  padding: 0px;
  margin: 0px;
}

.ml-10 {
  margin-left: 10px;
}

.add-client-container {
  height: fit-content !important;
  padding: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.action-icon {
  padding-right: 15px !important;
}

.bg-white {
  background-color: #fff;
}
.search-width {
  width: 30%;
}

.client-search {
  width: 30%;
  margin-left: -15px;
}

.w-100 {
  width: 100%;
}

.main-dashboard {
  height: 70vh;
  padding: 20px;

  .dashboard-title {
    font-size: 24px;
  }
}

.btn-container-end {
  display: flex;
  justify-content: flex-end;
}
.cardSpacing {
  padding: 12px 12px 12px 12px;
}

.employee-container {
  height: fit-content !important;
  padding: 20px;
}
