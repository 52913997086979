$transparent: rgba(0, 0, 0, 0);
$gradient1: #d9e5fd;
$gradient2: #f2f7fc;
$gradient3: #eaedf1;
$gradient4: #f3f4f5;
$success_color: #80c42a;
$error_color: #ee989a;
$white: #ffffff;
$blue: #1677ff;
$lightgray: #d3d3d3;
$lightblack: #0d0d0d4f;
$lightgray: #d3d3d3;
$errorColor: #ff4d4f;
$colorRed: rgb(255, 0, 0);
$white: #ffffff;
$blue: #1677ff;
$lightBlack: #0d0d0d4f;
$black: #35344c;
$colorPrimary: #00a8ad;
$colorBgPrimary: #eeeeee;
$colorBgBase: #f2f6fa;
$colorText: #35344c;
$colorBgGrey: #617294;
$colorLightGrey: #919cb0;
$colorBorder: rgb(242, 246, 250);
$colorTextPlaceholder: #96a2ba;
$colorBgBaseDark: rgb(218, 223, 233);
$colorBgBaseGraySecondary: #dadfe9;
$colorGraySecondary: #dadfe9;
$colorGray: #fbe2e2;
$colorTextSecondary: #505a78;
$colorBgBaseSecondary: rgba(0, 168, 173, 0.1);
$colorBgBaseTernary: rgba(0, 168, 173, 0.2);
$colorBgBasePrimary: rgba(0, 27, 75, 0.15);
$colorBgBaseGray: #edeef1;
$colorBgTextSecondary: #888;
$colorBgSecondary: #a3a3a3;
$colorBgBaseLightGray: rgba(238, 241, 246, 1);
$colorBgBaseDarkGray: rgba(255, 255, 255, 0.5);
$colorBgBaseCream: rgba(247, 162, 69, 0.2);
$colorLightBlack: rgba(53, 52, 76, 0.1);
$colorGreyBlack: rgba(0, 26, 69, 0.14);
$colorBlack: rgba(0, 0, 0, 0.65);
$colorPureBlack: #000000;
$colorBaseBlack: #000;
$colorLightYellow: rgba(255, 255, 255, 0.1);
$colorBgBaseOrange: #f7a245;
$colorBaseOrange: #e1b98f;
$colorTextLightGray: #617294;
$colorTextRed: #ed6d6e;
$colorDarkRed: #b13e25;
$colorDarkWhite: #edf1f5;
$colorTextBgRed: #f9ebeb;
$colorBgBaseLightRed: rgba(237, 109, 110, 0.1);
$colorBgBaseLightGreen: rgba(247, 162, 69, 0.1);
$colorBgBaseLightBlack: rgba(0, 108, 111, 0.12);
$colorBgLightBlack: rgba(0, 0, 0, 0.05);
$colorBgBlack: rgba(0, 0, 0, 0.5);
$colorBgDarkBlack: rgba(0, 26, 69, 0.06);
$colorBgLightBlack: rgba(0, 0, 0, 0.09);
$colorBgLightBlue: rgba(0, 12, 96, 0.07);
$colorTextBrown: #542317;
$errorBgColor: #f8c5c5;
$successBgColor: #99deae;
$greenDateChipColor: rgba(0, 168, 173, 0.2);
$infoBgColor: #f5cd9f;
$iPadPortraitMaxWidth: 992px;
$iPadLandScapeMaxWidth: 1200px;
$mobileMaxWidth: 576px;
