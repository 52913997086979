@import '../../styles/colorMapping.scss';
.handPointer:hover {
  cursor: pointer;
}
.fontFamily {
  font-family: 'Montserrat', sans-serif;
}
.cardSpacing {
  padding: 12px 12px 12px 12px;
}
.comingSoon {
  font-size: 15px;
  font-weight: 500;
  color: color(grey);
}
.passwordCriteria {
  font-size: 12px;
  font-weight: 500;
}
.form-wrapper {
  padding: 20px 11px 20px 11px;
  .form-error {
    color: color(errorRed);
    margin: 5px 0;
  }
}
.user-form-error {
  color: color(errorRed);
  margin: 5px 0;
}
.full-size-button {
  width: 100%;
}
.ant-btn.ant-btn-secondary.secondary-button {
  background-color: color(grey);
  color: color(white);
  border-color: color(grey);
}
.ant-btn:hover {
  opacity: 0.5;
}
.form-container {
  .common-btn {
    margin-top: 15px;
    background-color: color(primary);
    border-color: color(grey);
  }

  .common-btn:hover {
    background-color: color(primary);
    border-color: color(primary);
    color: color(lightBlue);
    opacity: 0.5;
  }
  .common-text-field {
    margin-top: 10px;
  }
}
.ant-spin-dot-item {
  background-color: color(primary) !important;
}
.loader-class {
  .ant-spin-spinning {
    position: absolute;
    left: 57%;
    top: 47%;
    z-index: 9999;
  }
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #f0f2f5;
}
