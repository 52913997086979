@import '../../../../../styles/variables.scss';

.ant-message {
  .ant-message-notice-content {
    padding: 0px !important;
  }
  .ant-message-custom-content.ant-message-error {
    background-color: $error_color !important;
    color: $white;
    padding: 10px 16px;
    font: 500 14px;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: all;
  }
}

.ant-message {
  .ant-message-custom-content.ant-message-success {
    background-color: $success_color !important;
    color: $white;
    padding: 10px 16px;
    font: 500 14px;
    max-width: 360px !important;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: all;
  }
}

.ant-message-notice-content {
  padding: 0px;
  margin-inline-start: auto;
}

.custom-message-area {
  text-align: left;
  padding-left: 26px;
  padding-top: 8px;
}

.toast-area {
  height: max-content;

  margin-inline-start: auto !important;
  display: flex;
}

.message-header {
  text-align: left;
  display: flex;
}

.message-icon {
  color: $white;
}

.message-label {
  font-weight: 700;
}
